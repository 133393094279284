export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: '관리자 공지사항',
    route: 'notice-admin',
    icon: 'BookOpenIcon',
  },
  {
    title: '환경 설정',
    icon: 'SettingsIcon',
    children: [
      {
        title: '기본 설정',
        route: 'basic-settings',
      },
      {
        title: '등급 관리',
        route: 'rank-settings',
      },
      {
        title: '이벤트 관리',
        route: 'event-settings',
      },
      {
        title: '계좌 관리',
        route: 'account-settings',
      },
      {
        title: 'IP 관리',
        route: 'ip-settings',
      },
      {
        title: '알람 관리',
        route: 'alarms-settings',
      },
    ],
  },
  {
    title: '결제 관리',
    icon: 'DollarSignIcon',
    children: [
      {
        title: '충환전 관리',
        route: 'cash-in-out-list',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '충전 관리',
        route: 'deposit-list',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '환전 관리',
        route: 'exchange-list',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '총판 출금 관리',
        route: 'branch-cashout-list',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
    ],
  },
  {
    title: '총판 관리',
    icon: 'UserCheckIcon',
    children: [
      {
        title: '총판 관리',
        route: 'branch-list',
      },
      {
        title: '총판 일정산관리',
        route: 'branch-calculate',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '총판 그룹별관리',
        route: 'branch-calculate-group',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      // {
      //   title: '총판 월별현황',
      //   route: 'branch-monthly',
      //   tag: 'NEW',
      //   tagVariant: 'light-success',
      // },
    ],
  },
  {
    title: '회원 관리',
    icon: 'UsersIcon',
    children: [
      {
        title: '회원 관리',
        route: 'apps-users-list',
      },
      {
        title: '실시간 접속 회원',
        route: 'apps-users-list-now',
      },
      {
        title: '회원 접속 로그',
        route: 'users-connect-logs',
      },
      {
        title: '신규 회원',
        route: 'users-Signup-list',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
    ],
  },
  {
    title: '게임 관리',
    icon: 'PlayCircleIcon',
    children: [
      {
        title: '게임 설정',
        route: 'game-settings',
      },
      {
        title: '배팅 리스트 (종합)',
        route: 'betting-list',
      },
      {
        title: '배팅 리스트 (경기별)',
        route: 'betting-list-by-match',
      },
      {
        title: '경기 관리 (발매)',
        route: 'match-manage-betting',
      },
      {
        title: '경기 관리 (결과)',
        route: 'match-manage-result',
      },
      {
        title: '적중 결과',
        route: 'refund-hit-result',
      },
      {
        title: '수동 경기 (등록)',
        route: 'custom-match-addon',
      },
      {
        title: '수동 경기 (결과)',
        route: 'custom-match-result',
      },
      {
        title: '게임 설정 (카지노)',
        route: 'game-settings-casino',
      },
      {
        title: '카지노 게임 내역',
        route: 'casino-game-transaction',
      },
    ],
  },
  {
    title: '게시판 관리',
    icon: 'EditIcon',
    children: [
      {
        title: '공지사항 관리',
        route: 'notice-settings',
      },
      {
        title: 'FAQ 관리',
        route: 'faq-settings',
      },
      {
        title: '팝업 관리',
        route: 'popup-settings',
      },
    ],
  },
  {
    title: '쪽지 관리',
    icon: 'MailIcon',
    children: [
      {
        title: '쪽지 발송',
        route: 'messages-send-admin',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '쪽지함(회원)',
        route: 'messages-history-user',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '쪽지함(비회원)',
        route: 'messages-guest',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
      {
        title: '쪽지함(관리자)',
        route: 'messages-history-admin',
        tag: 'NEW',
        tagVariant: 'light-success',
      },
    ],
  },
  {
    title: '슈퍼 관리자',
    icon: 'MonitorIcon',
    tagVariant: 'light-success',
    children: [
      {
        title: '관리자 공지사항',
        route: 'notice-admin-create',
      },
      {
        title: '배치 스케줄 로그',
        route: 'batch-schedule-logs',
      },
      {
        title: '어드민 IP관리',
        route: 'admin-ip-setting',
      },
      {
        title: '관리자 권한설정',
        route: 'site-admin-set-role',
      },
      {
        title: '변경이력 조회',
        route: 'approval-logs',
      },
      {
        title: '마스터 쪽지 관리',
        route: 'messages-history-master',
      },
    ],
  },
]
